import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { UserRole } from "../../../constants.js";
import { SpaceData } from "~/api/v1/Space";
import {
  useSpace,
  useSpaces,
} from "~/hooks/api.js";
import Dashboard from "~/components/pages/Dashboard/Dashboard.js";
import LocationCardPage from "~/components/pages/LocationCardPage";
import ArrowHeader from "~/components/portal/ArrowHeader/ArrowHeader.js";
import ConfirmActionModal from "~/components/portal/ConfirmActionModal/ConfirmActionModal.js";
import AuthService from "~/services/AuthService.js";
import RESTService from "~/services/RESTService.js";
import getNeighbors from "~/utils/getNeighbors.js";

const nop = () => {
    /* NOP */
};

const getHref = (space: SpaceData) => `/locations/${space.identifier}/dashboard`;

const LocationsDashboardPage: React.FC = () => {
    const { t } = useTranslation();
    const history = useNavigate();
    const { id: spaceId } = useParams<{ id: string }>();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const { data: spaces } = useSpaces({});
    const { data: space } = useSpace({
        spaceId,
    });

    const canDelete = AuthService.hasRole(UserRole.Admin);
    const canEdit = AuthService.hasRole(UserRole.Admin);

    const deleteLocation = canDelete ? () => {
        void RESTService.delete<boolean>({
            url: `spaces/${spaceId}`,
            successCallback(): void {
                history("/");
            },
        });
    } : nop;

    const showModal = canDelete ? () => {
        setShowDeleteModal(true);
    } : nop;

    const hideModal = canDelete ? () => {
        setShowDeleteModal(false);
    } : nop;

    if (!space) {
        return <></>;
    }

    const [leftArrow, rightArrow] = getNeighbors(space, spaces);

    const subSpaceIds = space?.sub_spaces.map((subspace) => subspace.identifier);
    const subSpaces = subSpaceIds && spaces?.filter((sp) => subSpaceIds?.includes(sp.identifier));

    return (
      <>
        <ConfirmActionModal
            title={t("confirmModal.defaultTitle")}
            message={t("confirmModal.defaultDeleteMessage")}
            onConfirm={deleteLocation}
            onDecline={hideModal}
            show={showDeleteModal}
        />

        <ArrowHeader
          name={space.name}
          parent={space.super_space}
          leftUrl={leftArrow ? `/locations/${leftArrow}/dashboard` : null}
          rightUrl={rightArrow ? `/locations/${rightArrow}/dashboard` : null}
          editText={t("locations.dashboard.header.button")}
          editUrl={canEdit ? `/locations/${spaceId}/edit` : null}
        />

        {subSpaceIds.length > 0 ? (
          <LocationCardPage
            spaces={subSpaces}
            getHref={getHref}
          />
        ) : (

          <Dashboard
            id={spaceId}
            data={space}
            deleteAction={showModal}
          />
        )}
      </>
    );
};

export default LocationsDashboardPage;
