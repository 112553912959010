import { useQueryClient } from "@tanstack/react-query";
import { clsx } from "clsx";
import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  ClassifiedGateData,
  GateData,
  HeatmapData,
  PresenceData,
  PresenceTimeData,
  UniqueCounterData,
} from "~/api/v1/ReportData/index.js";
import DeviceList from "~/components/Dashboard/DeviceList/DeviceList.js";
import MiniInfoCardCapacity from "~/components/Dashboard/MiniInfoCardCapacity/MiniInfoCardCapacity.js";
import TvScreenLinkCard from "~/components/locations/TvScreenLinkCard/TvScreenLinkCard.js";
import Header from "~/components/portal/HeaderNew/Header.js";
import { OCCUPATION_STATES } from "~/constants.js";
import {
  useSpace,
  useSpaceClassifiedGates,
  useSpaceClassifiedGatesLatestData,
  useSpaceGates,
  useSpaceGatesLatestData,
  useSpaceHeatmaps,
  useSpaceHeatmapsLatestData,
  useSpacePresenceTimeZones,
  useSpacePresenceTimeZonesLatestData,
  useSpacePresenceZones,
  useSpacePresenceZonesLatestData,
  useSpacePseudogates,
  useSpacePseudogatesLatestData,
  useSpaceUniqueCounters,
  useSpaceUniqueCountersLatestData,
} from "~/hooks/api.js";
import {
  useWebSocketUpdatedMultiQuery,
  useWebSocketUpdatedQuery,
} from "~/hooks/websocketQuery.js";
import useWebSocketData from "~/websockets/useWebSocketData.js";
import useWebSocketSetter from "~/websockets/useWebSocketSetter.js";
import {
  LiveEventData,
  LocationOccupationData,
} from "~/websockets/websockets.js";

interface Item {
  identifier: string;
  name?: string;
  description?: string;
}
interface Data {}

interface LiveEventDataWithTime extends LiveEventData {
  time_stamp: number;
}

type StatProps = {
  label: string;
  value: any;
};

function Stat({ label, value }: StatProps) {
  let val: string;
  if (label == "time_stamp") {
    if (value) {
      const date: Date = new Date(value as number);
      //const secondsAgo = (Date.now() - value as number)/1000;
      val = date.toISOString(); //(${secondsAgo} seconds ago)
    } else {
      val = "-";
    }
  } else if (label == "grid") {
    const x = value?.length;
    const y = value?.length && value[0].length;
    val = `${x ?? 0}x${y ?? 0}`;
  } else if (typeof(value) == "object" && value.in !== undefined) {
    if (value.in == 0 && value.out == 0)
      return null;
    else
      val = `${value.in}/${value.out}`;
  } else val = value;

  return (
    <div className={clsx("flex flex-col gap-y-0.5")}>
      <div className="text-gray-700">{label}</div>
      <div className="font-bold tabular-nums">{val}</div>
    </div>
  );
}

type CardProps = {
  description: string;
  item: Item;
  data: Data | undefined;
};

type UcCardProps = {
  description: string;
  items: Item[];
  data: Data | undefined;
};

type LeCardProps = {
  description: string;
  data: Data;
};

type BaseCardProps = {
  description: string;
  items: {
    key: string;
    description: string;
  }[];
  data: Data | undefined;
};

const BaseCard = ({ description, items, data }: BaseCardProps) => {
  return (
    <div
      className={clsx(
        "bg-white w-full flex justify-bteween flex-col p-4 rounded shadow ring-1 ring-gray-200",
      )}
    >
      <h3 className="text-base pb-1.5 border-b border-gray-200 leading-tight">
        {description}
      </h3>
      {items.map((item) => (
        <h4 key={item.key}>{item.description}</h4>
      ))}
      <div className="flex pt-2 justify-between">
        {data
          ? Object.entries(data)
              .sort(
                ([key1], [key2]) =>
                  +(key2 == "time_stamp") - +(key1 == "time_stamp"),
              )
              .map(([key, val]) => <Stat key={key} label={key} value={val} />)
          : "-"}
      </div>
    </div>
  );
};

const Card = ({ description, item, data }: CardProps) => {
  return (
    <BaseCard
      description={description}
      items={[
        {
          key: "",
          description: `${item.identifier} - ${item?.name} - ${item?.description}`,
        },
      ]}
      data={data}
    />
  );
};

// TODO: rename
const UcCard = ({ description, items, data }: UcCardProps) => {
  return (
    <BaseCard
      description={description}
      items={items.map((item) => ({
        key: item.identifier,
        description: `${item.identifier} - ${item?.description}`,
      }))}
      data={data}
    />
  );
};

const LeCard = ({ description, data }: LeCardProps) => {
  return <BaseCard description={description} items={[]} data={data} />;
};

const AreaDebug: React.FC = () => {
  const { id: spaceId } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const [liveEvent, setLiveEvent] = useState<LiveEventDataWithTime>();
  const spaceOptions = { spaceId };
  const spaceNPHOptions = { spaceId, queryClient };
  const spacePHAOptions = { spaceId, queryClient, placeholderData: [] };
  const spacePHOOptions = { spaceId, queryClient, placeholderData: {} };
  const { data: space } = useSpace(spaceOptions);
  const { data: classifiedGates } = useSpaceClassifiedGates(spacePHAOptions);
  const { data: gates } = useSpaceGates(spacePHAOptions);
  const { data: heatmapZones } = useSpaceHeatmaps(spacePHAOptions);
  const { data: presenceZones } = useSpacePresenceZones(spacePHAOptions);
  const { data: presenceTimeZones } =
    useSpacePresenceTimeZones(spacePHAOptions);
  const { data: pseudogates } = useSpacePseudogates(spacePHAOptions);
  const { data: uniqueCounters } = useSpaceUniqueCounters(spacePHAOptions);

  const organizationId = space?.organization?.identifier;
  const occupation = useWebSocketData<LocationOccupationData>(
    `lococc-${spaceId}`,
  );
  useWebSocketSetter<LiveEventData>(
    `liveevents-${spaceId}`,
    useCallback((leData: LiveEventData) => {
      setLiveEvent({
        time_stamp: Date.now(),
        ...leData,
      });
    }, []),
    organizationId,
  );

  const { data: classifiedGateData, queryKey: classifiedGateQuery } =
    useSpaceClassifiedGatesLatestData(spacePHOOptions);
  const { data: gateData, queryKey: gateQuery } =
    useSpaceGatesLatestData(spacePHOOptions);
  const { data: heatmapZoneData, queryKey: heatmapQuery } =
    useSpaceHeatmapsLatestData(spacePHOOptions);
  const { data: presenceZoneData, queryKey: presenceQuery } =
    useSpacePresenceZonesLatestData(spacePHOOptions);
  const { data: presenceTimeZoneData, queryKey: presenceTimeQuery } =
    useSpacePresenceTimeZonesLatestData(spaceNPHOptions);
  const { data: pseudogateData, queryKey: pseudogateQuery } =
    useSpacePseudogatesLatestData(spacePHOOptions);
  const { data: uniqueCounterData, queryKey: uniqueCounterQuery } =
    useSpaceUniqueCountersLatestData(spaceNPHOptions);
  const classifiedGateIds = useMemo(
    () => classifiedGates.map((gate) => gate.identifier),
    [classifiedGates],
  );
  const gateIds = useMemo(() => gates.map((gate) => gate.identifier), [gates]);
  const heatmapZoneIds = useMemo(
    () => heatmapZones.map((zone) => zone.identifier),
    [heatmapZones],
  );
  const pseudogateIds = useMemo(
    () => pseudogates.map((gate) => gate.identifier),
    [pseudogates],
  );
  const presenceZoneIds = useMemo(
    () => presenceZones.map((zone) => zone.identifier),
    [presenceZones],
  );
  useWebSocketUpdatedMultiQuery<ClassifiedGateData>({
    queryKey: classifiedGateQuery,
    groupPrefix: `classifiedgate-${spaceId}`,
    items: classifiedGateIds,
    queryClient,
    organizationId,
  });
  useWebSocketUpdatedMultiQuery<GateData>({
    queryKey: gateQuery,
    groupPrefix: `gate-${spaceId}`,
    items: gateIds,
    queryClient,
    organizationId,
  });
  useWebSocketUpdatedMultiQuery<HeatmapData>({
    queryKey: heatmapQuery,
    groupPrefix: `heatmap-${spaceId}`,
    items: heatmapZoneIds,
    queryClient,
    organizationId,
  });
  useWebSocketUpdatedMultiQuery<PresenceData>({
    queryKey: presenceQuery,
    groupPrefix: `presence-${spaceId}`,
    items: presenceZoneIds,
    queryClient,
    organizationId,
  });
  useWebSocketUpdatedQuery<PresenceTimeData>({
    queryKey: presenceTimeQuery,
    methodName: `presencetime-${spaceId}`,
    queryClient,
    organizationId,
  });
  useWebSocketUpdatedMultiQuery<GateData>({
    queryKey: pseudogateQuery,
    groupPrefix: `pseudogate-${spaceId}`,
    items: pseudogateIds,
    queryClient,
    organizationId,
  });
  useWebSocketUpdatedQuery<UniqueCounterData>({
    queryKey: uniqueCounterQuery,
    methodName: `uniquecounter-${spaceId}`,
    queryClient,
    organizationId,
  });

  return (
    <StyledAreaDebug>
      <Header>
        <h2>
          {space?.name} - {space?.identifier}
        </h2>
      </Header>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 mt-4">
        {occupation && (
          <MiniInfoCardCapacity
            count={occupation.curOccupation}
            maxCount={occupation.maxOccupation}
            state={OCCUPATION_STATES[occupation.state]}
          />
        )}

        <DeviceList organizationId={space?.organization?.identifier} spaceId={spaceId} />

        <TvScreenLinkCard discriminator={space?.discriminator} />

        {liveEvent && (
          <LeCard
            key={`liveevent`}
            description={`Live event`}
            data={liveEvent}
          />
        )}
        {heatmapZones.map((heatmapZone) => (
          <Card
            key={`heatmap-${heatmapZone.identifier}`}
            description={`Heatmap-${heatmapZone.name}`}
            item={heatmapZone}
            data={heatmapZoneData[heatmapZone.identifier]}
          />
        ))}
        {classifiedGates.map((classifiedGate) => (
          <Card
            key={`classifiedGate-${classifiedGate.identifier}`}
            description={`ClassifiedGate-${classifiedGate.name}`}
            item={classifiedGate}
            data={classifiedGateData[classifiedGate.identifier]}
          />
        ))}
        {gates.map((gate) => (
          <Card
            key={`gate-${gate.identifier}`}
            description={`Gate-${gate.name}`}
            item={gate}
            data={gateData[gate.identifier]}
          />
        ))}
        {presenceZones.map((presenceZone) => (
          <Card
            key={`presence-${presenceZone.identifier}`}
            description={`Presence-${presenceZone.name}`}
            item={presenceZone}
            data={presenceZoneData[presenceZone.identifier]}
          />
        ))}
        {presenceTimeZones && presenceTimeZones.length > 0 && (
          <UcCard
            key={`presencetimezones`}
            description={`PresenceTimeZones`}
            items={presenceTimeZones}
            data={presenceTimeZoneData}
          />
        )}

        {pseudogates.map((pseudogate) => (
          <Card
            key={`pseudogate-${pseudogate.identifier}`}
            description={`Pseudogate-${pseudogate.name}`}
            item={pseudogate}
            data={pseudogateData[pseudogate.identifier]}
          />
        ))}

        {uniqueCounters && uniqueCounters.length > 0 && (
          <UcCard
            key={`uniquecounters`}
            description={`UniqueCounters`}
            items={uniqueCounters}
            data={uniqueCounterData}
          />
        )}
      </div>
    </StyledAreaDebug>
  );
};

const StyledAreaDebug = styled.div``;

export default AreaDebug;
