// cSpell:language en

export default {
  translation: {
    navigation: {
      header: "Menu",
      search: "Search",
    },

    validationRules: {
      mixed: {
        default: "${path} is invalid",
        required: "Please enter this field.",
        oneOf: "${path} has to contain the following values: ${values}",
        notOneOf:
          "${path} is not allowed to have the following values: ${values}",
      },

      string: {
        length: "This field needs to have a length of exactly ${length}",
        min: "This field has a minimum length of ${min} characters",
        max: "This field has a maximum length of ${max} characters",
        matches: '${path} has to match: "${regex}"',
        email: "Please enter a valid e-mail address.",
        url: "${path} has got to be a valid URL",
        lowercase: "${path} can only contain lowercase letters",
        uppercase: "${path} can only contain uppercase letters",
        valid_password: "Enter a valid password",
        password_match: "The passwords need to match",
        invalid_phone: "Invalid phone number",
      },

      number: {
        min: "${path} needs to have a minimum length ${min}",
        max: "${path} has a maximum length of ${max}",
        lessThan: "${path} has to be less than ${less}",
        moreThan: "Field values needs to be greater than ${more}",
        notEqual: "${path} may not be equal to ${notEqual}",
        positive: "${path} has to be a positive number",
        negative: "${path} has to be a negative number",
        integer: "Please enter a valid numerical value.",
      },

      date: {
        min: "${path} must have a date later than ${min}",
        max: "${path} must have a date earlier than ${max}",
      },

      object: {
        noUnknown:
          "${path} field cannot have keys not specified in the object shape",
      },

      array: {
        min: "${path} has to contain at least ${min} items",
        max: "${path} can contain a maximum of ${max} items",
      },
    },

    attributes: {
      name: "Name",
      initials: "Initials",
      firstName: "First name",
      lastName: "Last name",
      password: "Password",
      passwordConfirmation: "Password (repeat)",
      createdAt: "Created at",
      updatedAt: "Updated at",
      gender: "Gender",

      address: "Address",
      postalCode: "Postal code",
      houseNumber: "Number",
      houseNumberSuffix: "Suffix",
      street: "Street",
      city: "City",
      country: "Country",
      phoneNumber: "Phone number",
      email: "E-mail",
      profileImage: "Profile image",

      companyName: "Company name",
      companyLogo: "Company logo",
      legalForm: "Legal form",
      vatNumber: "VAT number",
      companyRegistrationNumber: "CoC number",

      dateOfBirth: "Date of birth",
    },

    dateValues: {
      days: {
        short: {
          mon: "mon",
          tue: "tue",
          wed: "wed",
          thu: "thu",
          fri: "fri",
          sat: "sat",
          sun: "sun",
        },
      },
    },

    state: {
      ok: "Normal",
      warning: "Busy",
      danger: "Full",
    },

    button: {
      submit: "Submit",
    },

    confirmModal: {
      defaultTitle: "Warning",
      defaultMessage: "Are you sure you want to do this?",
      defaultDeleteMessage: "Are you sure you want to delete this?",
      buttonYes: "Yes",
      buttonNo: "No",
    },

    croppedFileUpload: {
      uploadFile: "Upload image",
      saveFile: "Save image",
      deleteButton: "Delete image",
      errors: {
        fileType: "Only a .png, .jpg of .jpeg file is allowed",
        fileSize: "The maximum file size is {{size}}MB",
      },
    },

    connection: {
      lostConnection: "Lost connection (reconnecting...)",
    },

    apiErrors: {
      notFound: "We know where people are, but not where this page has gone",
      notFoundPage:
        "We know you wanted to go somewhere, but could not find it here",
      unexpected:
        'Something went wrong. If you need help, please contact support <a href="mailto:support@husense.nl">support@husense.nl</a>',
      contact: "Please contact support@husense.nl",
    },

    /////////////////// specific ///////////////////
    auth: {
      login: {
        header: "Login",
        instructions: "Enter your credentials",
        email: "Email address",
        password: "Password",
        passwordConfirmed: "Confirm password",
        submit: "Login",
        forgotPassword: "Password forgotten?",
        register: "Klik here to register yourself",
        emailVerification:
          "Your email has been confirmed, you are now able to login",
        backToLogin: "Back to login",
        goToTheLoginPage: "Or go to the login page",
      },
      register: {
        title: "Register",
        thankYou: {
          header: "Thank you for registering on Husense",
          body: "An email with loginlink will be send to you.",
        },
        submit: "Register",
      },
      responses: {
        incorrectCredentials: "Email address and password do not match ",
        unknown: "A unknown error has occured",
      },
      logout: "Logout",
      forgotPassword: {
        title: "Password forgotten?",
        body: "We will send a link to the e-mail you entered. Use this link to set a new password for your account.",
        submit: "Send",
        thankYou: {
          header: "Password reset",
          body: "Open the email we have sent to reset the password.",
        },
        unknownEmail: "This email address {{email}} is unknown by us.",
      },
      resetPassword: {
        title: "New password",
        resetPasswordBody: "Enter your password twice",
        firstTimeBody: "Create a safe password for your new account",
        password: "Password",
        passwordConfirmed: "Password confirm",
        submit: "Change password",
        invalidPassword:
          "Passwords do not match or does not have the minimum length of 8 characters.",
      },
      passwordCheck: {
        minLength: "Minimal 8 characters",
        smallLetter: "Small letters",
        largeLetter: "Capital letters",
        number: "Minimal a number",
        nonaplha: "Non aplha-numeric #@:$%^&",
      },
      emailVerification: {
        error: {
          header: "E-mail verification error",
          body: "This link is not valid. Please reset your password via the Password reset form.",
        },
      },
    },

    locations: {
      types: {
        none: "None",
        building: "Building",
        floor: "Floor",
        room: "Room",
      },
      index: {
        header: {
          title: "Locations",
          button: "Add location",
        },
      },
      form: {
        header: {
          create: "Create location",
          edit: "Edit location",
          times: "Opening times",
        },
        name: "Location name",
        type: "Location type",
        capacity: "Capacity",
        areaSize: "Area size (m2)",
        openingTime: "Open",
        closingTime: "Closed",
        description: "Description",
        countResetTime: "Location count reset time",
      },
      dashboard: {
        header: {
          title: "Location dashboard",
          liveEvents: "Live events",
          button: "Edit location",
          buttonDelete: "Delete location",
        },
      },
      area: {
        index: {
          header: {
            title: "Area Selection",
            button: "Add new Area",
          },
        },
      },
      smartcity: {
        dashboard: {
          header: {
            title: "Husense SmartCity dashboard",
          },
        },
      },
    },

    devices: {
      types: {
        none: "None",
        peopletrackingsensor: "People counting sensor",
      },
      index: {
        header: {
          title: "IOT devices",
          button: "Add device",
        },
      },
      form: {
        name: "Device naam",
        type: "Device type",
        space: "Location",
        connString: "Connectionstring",
        buttonBack: "Back to device overview",
      },
    },

    devicesList: {
      header: {
        title: "IOT Devices",
      },
    },

    bigScreen: {
      header: {
        ok: "Welcome",
        warning: "Please keep your distance!",
        danger: "It is too busy",
      },
      subHeader: {
        ok: "Please keep your distance",
        warning: "",
        danger: "Please wait",
        lostConnection: "Screen out of use",
      },
      peopleSubText: "People inside",
      poweredBy: "Powered by",
    },

    dashboard: {
      popularTimes: {
        header: "Popular times",
      },
      capacity: {
        header: "Capacity",
        lostConnection: "Trying to establish connection...",
      },
      HourlyPresence: {
        header: "Max Hourly Presence",
      },
      HourlyGatePresence: {
        header: "Hourly Presence",
      },
      HourlyVisitors: {
        header: "Hourly Visitors",
      },
      liveEvents: {
        header: "Live events",
        numberPeople: "# people",
        filled: "filled %",
      },
      liveEventsCum: {
        header: "Daily",
        peak: "Peak",
        average: "Average",
        utilization: "Util. %",
      },
      floorOverview: {
        header: "Floor overview",
      },
    },

    account: {
      organization: {
        header: "Organization details",
      },
      user: {
        header: "Personal account details",
        passwordChange: {
          header: "Change password",
          body: "Changing you password is because of security reasons only available via the password reset page",
          button: "Click here to change your password",
        },
      },
      administration: {
        header: "Administration contact",
      },
      updateMessage: "Data successfully saved!",
    },

    zonesList: {
      header: {
        title: "Zones",
      },
      global: {
        name: "Total",
      },
    },
  },
};
