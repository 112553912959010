import React from "react";
import { Link } from "react-router-dom";
import BlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.js";
import StyledTvScreenLinkCard from "./TvScreenLinkCard.styles.js";

type TvScreenLinkCardProps = {
  discriminator: string;
};

const TvScreenLinkCard: React.FC<TvScreenLinkCardProps> = ({
  discriminator,
}: TvScreenLinkCardProps) => {
  const origin = window.location.origin;

  if (!discriminator) return <></>;

  return (
    <StyledTvScreenLinkCard>
      <BlockWrapper>
        <Link
          to={`/screen/${discriminator}/dashboard`}
          target="_blank"
        >{`${origin}/screen/${discriminator}/dashboard`}</Link>
      </BlockWrapper>
    </StyledTvScreenLinkCard>
  );
};

export default TvScreenLinkCard;
