import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { HourlyWeekData } from "~/api/v1/ReportData/index.js";
import { queryFnWeek, WeeklyDataOptions } from "./week.js";

export function usePeakPresenceData({
  spaceId,
  enabled: enabledOption,
  date,
}: WeeklyDataOptions): UseQueryResult<HourlyWeekData> {
  const queryKey = ["Spaces", spaceId, "PeakPresence", date.toString()];
  const enabled = (enabledOption === undefined || enabledOption) && !!spaceId;
  const staleTime = 60 * 60 * 1000;
  return useQuery({
    enabled,
    queryKey,
    queryFn: queryFnWeek<HourlyWeekData>,
    staleTime,
  });
}
