import { useQueryClient } from "@tanstack/react-query";
import { clsx } from "clsx";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GateData, PresenceData } from "../../../api/v1/ReportData/index.js";
import { GateInfo, PresenceInfo } from "../../../api/v1/ReportInfo/index.js";
import {
  useSpaceGates,
  useSpaceGatesLatestData,
  useSpacePresenceZoneLatestData,
  useSpacePresenceZones,
  useSpacePresenceZonesLatestData,
  useSpacePseudogateLatestData,
  useSpacePseudogates,
  useSpacePseudogatesLatestData,
} from "../../../hooks/api.js";
import {
  useWebSocketUpdatedMultiQuery,
  useWebSocketUpdatedQuery,
} from "../../../hooks/websocketQuery.js";
import useWebSocketMulti from "../../../websockets/useWebSocketMulti.js";

type ZoneListProps = {
  organizationId: string;
  spaceId: string;
};

type MultiZoneProps = {
  organizationId: string;
  spaceId: string;
  presenceZoneIds?: PresenceInfo["identifier"][];
  //pseudogateIds?: GateInfo["identifier"][];
};

type HackAtriumProps = {
  organizationId: string;
  spaceId: string;
  presenceZoneIds: PresenceInfo["identifier"][];
  gateIds: GateInfo["identifier"][];
};

type ZoneProps = {
  organizationId: string;
  spaceId: string;
  presenceZone?: PresenceInfo;
  pseudogate?: GateInfo;
};

type WrapMultiPresenceProps = {
  organizationId: string;
  spaceId: string;
  presenceZoneIds: PresenceInfo["identifier"][];
};

type WrapMultiGateProps = {
  organizationId: string;
  spaceId: string;
  gateIds: GateInfo["identifier"][];
};

type PresenceCardProps = {
  label: string;
  value: number;
};

type WrapPresenceProps = {
  organizationId: string;
  spaceId: string;
  presenceZoneId?: PresenceInfo["identifier"];
};

type WrapPseudogateProps = {
  organizationId: string;
  spaceId: string;
  pseudogateId?: GateInfo["identifier"];
};

const FALLBACK_VALUE = "-";
const SHOULD_RENDER_TOTAL = false;
const SHOULD_RENDER_LIVE_DATA = true;

function CardStat({
  label,
  value,
  className,
}: {
  label?: React.ReactNode;
  value?: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={clsx("flex flex-col gap-y-0.5", className)}>
      <div className="text-sm font-medium text-gray-700">{label}</div>
      <div className="text-5xl font-bold tabular-nums">
        {value ?? FALLBACK_VALUE}
      </div>
    </div>
  );
}

const PresenceCard = ({
  label,
  value,
}: PresenceCardProps) => {
  return (
    <CardStat
      className="items-end"
      label={
        <div className="flex items-center">
          <span className="relative flex h-1.5 w-1.5 mr-1.5">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-1.5 w-1.5 bg-green-500"></span>
          </span>
          <span>{label}</span>
        </div>
      }
      value={value}
    />
  );
}

const WrapPresence = ({
  organizationId,
  spaceId,
  presenceZoneId,
}: WrapPresenceProps) => {
  const queryClient = useQueryClient();
  const methodName = useMemo(
    () => `presence-${spaceId}-${presenceZoneId}`,
    [spaceId, presenceZoneId],
  );

  const { data: presenceData, queryKey } = useSpacePresenceZoneLatestData({
    spaceId,
    presenceZoneId,
    queryClient,
  });
  useWebSocketUpdatedQuery<PresenceData>({
    queryKey,
    methodName,
    queryClient,
    organizationId,
  });

  const peoplePresent = presenceZoneId && presenceData && presenceData.person;

  return (
    <PresenceCard label="Live" value={peoplePresent ?? 0} />
  );
};

const WrapMultiPresence = ({
  organizationId,
  spaceId,
  presenceZoneIds,
}: WrapMultiPresenceProps) => {
  const [parts, setParts] = useState<Record<string, number>>(null);
  const methodNames = useMemo(
    () =>
      presenceZoneIds?.map((zoneId) => `presence-${spaceId}-${zoneId}`) ?? [],
    [spaceId, presenceZoneIds],
  );

  const methodFunction = useCallback(
    (zoneId: string, message: string) => {
      const data: PresenceData = JSON.parse(message);
      setParts((parts) => ({ ...parts, [zoneId]: data.person }));
      console.log(`MultiPres callback ${spaceId} ${zoneId}`, data, parts);
    },
    [spaceId],
  );

  const total = useMemo<number>(() => {
    if (parts === null) return 0;
    return Object.values(parts).reduce((acc, val) => acc + val, 0);
  }, [parts]);
  useWebSocketMulti(methodNames, methodFunction, organizationId);

  return <PresenceCard label="Live" value={total} />;
};

const WrapMultiGate = ({
  organizationId,
  spaceId,
  gateIds,
}: WrapMultiGateProps) => {
  const queryClient = useQueryClient();
  const { data }  = useSpaceGatesLatestData({
    spaceId,
    queryClient,
  });
  const total = useMemo(() => Object.entries(data ?? {})
    .filter(([gateId]) => gateIds.includes(gateId))
    .map(([gateId, data]) => data?.in ?? 0)
    .reduce((acc, val) => acc + val, 0)
    ?? 0,
    [gateIds, data]);

  return <CardStat label="Total" value={total} />;
};

const WrapPseudogate = ({
  organizationId,
  spaceId,
  pseudogateId,
}: WrapPseudogateProps) => {
  const queryClient = useQueryClient();
  const methodName = useMemo(
    () => `pseudogate-${spaceId}-${pseudogateId}`,
    [spaceId, pseudogateId],
  );

  const { data: pseudogateData, queryKey } = useSpacePseudogateLatestData({
    spaceId,
    pseudogateId,
    queryClient,
  });
  useWebSocketUpdatedQuery<GateData>({
    queryKey,
    methodName,
    queryClient,
    organizationId,
  });

  const visitors = pseudogateId && pseudogateData && pseudogateData.in;

  return <CardStat label="Total" value={visitors ?? FALLBACK_VALUE} />;
};

const MultiZone = ({
  organizationId,
  spaceId,
  presenceZoneIds,
  /*pseudogateIds,*/
}: MultiZoneProps) => {
  const { t } = useTranslation();

  const description = t("zonesList.global.name");

  return (
    <div className="bg-white w-full flex justify-between flex-col p-4 rounded shadow ring-1 ring-gray-200 col-span-full">
      <h3 className="text-base pb-1.5 border-b border-gray-200 leading-tight">
        {description}
      </h3>
      <div className="flex pt-2 justify-between">
        <WrapMultiPresence
          organizationId={organizationId}
          spaceId={spaceId}
          presenceZoneIds={presenceZoneIds}
        />
      </div>
    </div>
  );
};

const HackAtriumZone = ({
  organizationId,
  spaceId,
  presenceZoneIds,
  gateIds,
}: HackAtriumProps) => {
  const description = "Gehele expositie";
  //console.log("Gates & zones", gateIds, presenceZoneIds);

  return (
    <div
      className={clsx(
        "bg-white w-full flex justify-between flex-col p-4 shadow-box rounded-md",
      )}
    >
      <h3 className="text-base pb-1.5 border-b border-gray-200 leading-tight">
        {description}
      </h3>
      <div className="flex pt-2 justify-between">
        {gateIds.length > 0 ? (
          <WrapMultiGate
            organizationId={organizationId}
            spaceId={spaceId}
            gateIds={gateIds}
          />
        ) : null}
        {presenceZoneIds.length > 0 ? (
          <WrapMultiPresence
            organizationId={organizationId}
            spaceId={spaceId}
            presenceZoneIds={presenceZoneIds}
          />
        ) : null}
      </div>
    </div>
  );
};

const Zone = ({
  organizationId,
  spaceId,
  presenceZone,
  pseudogate,
}: ZoneProps) => {
  const description =
    presenceZone?.description || pseudogate?.description || "";

  return (
    <div
      className={clsx(
        "bg-white w-full flex justify-between flex-col p-4 shadow-box rounded-md",
      )}
    >
      <h3 className="text-base pb-1.5 border-b border-gray-200 leading-tight">
        {description}
      </h3>
      <div className="flex pt-2 justify-between">
        <WrapPseudogate
          organizationId={organizationId}
          spaceId={spaceId}
          pseudogateId={pseudogate?.identifier}
        />
        {SHOULD_RENDER_LIVE_DATA ? (
          <WrapPresence
            organizationId={organizationId}
            spaceId={spaceId}
            presenceZoneId={presenceZone?.identifier}
          />
        ) : null}
      </div>
    </div>
  );
};

const ZoneList = ({ organizationId, spaceId }: ZoneListProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const spaceOptions = {
    spaceId,
  };
  const spaceClientOptions = {
    ...spaceOptions,
    queryClient,
  };

  const { data: presenceZones } = useSpacePresenceZones(spaceOptions);
  const { data: pzAllData } =
    useSpacePresenceZonesLatestData(spaceClientOptions); // Here to pre-load the whole set

  const { data: pseudogates } = useSpacePseudogates(spaceOptions);
  const { data: pgAllData } = useSpacePseudogatesLatestData(spaceClientOptions); // pre-load

  const { data: gates } = useSpaceGates(spaceOptions);
  const { data: gAllData, queryKey: gAllDataKey } = useSpaceGatesLatestData(spaceClientOptions); // pre-load

  // Hack for smart@sea
  const gateLeft = "gate_left";
  const gateRight = "gate_right";
  const presLeft = "atrium_left";
  const presRight = "atrium_right";

  const hackGate = useMemo(() => [gateLeft, gateRight], []);
  const hackPres = useMemo(() => [presLeft, presRight], []);
  const usedHackGates = useMemo(() =>
    gates
      ?.filter((gate) => hackGate.includes(gate.name))
      ?.map((gate) => gate.identifier)
      ?? [],
    [gates]);
  const usedHackPres = useMemo(() =>
    presenceZones
      ?.filter((zone) => hackPres.includes(zone.name))
      ?.map((zone) => zone.identifier)
      ?? [],
    [presenceZones]);

  useWebSocketUpdatedMultiQuery<GateData>({
    queryKey: gAllDataKey,
    groupPrefix: `gate-${spaceId}`,
    items: usedHackGates,
    queryClient,
    organizationId,
  });

  const zoneNames = useMemo(() => {
    const names: Set<string> = new Set<string>();
    presenceZones
      ?.filter((zone) => zone.name !== null && !hackPres.includes(zone.identifier))
      ?.forEach((zone) => names.add(zone.name));
    pseudogates
      ?.filter((gate) => gate.name !== null)
      ?.forEach((gate) => names.add(gate.name));
    return Array.from(names.values());
  }, [presenceZones, pseudogates]);

  const globalPresenceZoneIds = useMemo(() => {
    return presenceZones
      ?.filter((zone) => zone.name === null)
      ?.map((zone) => zone.identifier);
  }, [presenceZones]);

  const isHackAtrium = useMemo(() => {
    return usedHackGates.length > 0 || usedHackPres.length > 0;
  }, [usedHackGates, usedHackPres]);

  //    {pseudogateIds={pseudogates
  //        ?.filter((gate) => gate.name == undefined)
  //        ?.map((gate) => gate.identifier)}}
  return (
    <>
      {/* <div className="p-4 bg-white rounded ring-1 ring-gray-200 shadow">
        <h2 className="text-2xl font-medium">{t("zonesList.header.title")}</h2>
      </div> */}
      {SHOULD_RENDER_TOTAL ? (
        <MultiZone
          key={`zone-global`}
          organizationId={organizationId}
          spaceId={spaceId}
          presenceZoneIds={globalPresenceZoneIds}
        />
      ) : null}
      {isHackAtrium ? (
        <HackAtriumZone
          key={`zone-atrium`}
          organizationId={organizationId}
          spaceId={spaceId}
          presenceZoneIds={usedHackPres}
          gateIds={usedHackGates}
        />
      ) : null}
      {zoneNames.map((zoneName) => (
        <Zone
          key={`zone-${zoneName}`}
          organizationId={organizationId}
          spaceId={spaceId}
          presenceZone={
            presenceZones?.find((zone) => zone.name == zoneName) ?? undefined
          }
          pseudogate={
            pseudogates?.find((gate) => gate.name == zoneName) ?? undefined
          }
        />
      ))}
    </>
  );
};

export default ZoneList;
