import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Image } from "../../../api/v1/image.js";
import { OCCUPATION_STATES, OccupationState } from "../../../constants.js";
import { useWebSocketReconnecting } from "../../../websockets/WebSocketProvider.js";
import useWebSocketData from "../../../websockets/useWebSocketData.js";
import { LocationOccupationData } from "../../../websockets/websockets.js";
import BlockWrapper from "../../atoms/BlockWrapper/BlockWrapper.js";
import DistanceBlock from "../../atoms/DistanceBlock/DistanceBlock.js";
import StyledLocationCard from "./LocationCard.styles.js";

type LocationCardProps = {
  locationId: string | null;
  locationName: string;
  locationCity: string;
  image: Image | null;
  initialCount?: number;
  initialMaxCount?: number;
  initialState?: OccupationState;
  href?: string;
};

type CountBlockProps = {
  count: number;
  maxCount: number;
  state: OccupationState;
};

const CountBlock: React.FC<CountBlockProps> = ({
  count,
  maxCount,
  state,
}: CountBlockProps) => {
  const { t } = useTranslation();
  const reconnecting = useWebSocketReconnecting();

  if (reconnecting)
    return <div className="countWrapper">{t("connection.lostConnection")}</div>;
  else
    return (
      <div className="countWrapper">
        <div className="count">
          {count} / {maxCount}
        </div>
        <DistanceBlock state={state} />
      </div>
    );
};

const LocationCard: React.FC<LocationCardProps> = ({
  locationId,
  locationName,
  locationCity,
  image,
  initialCount,
  initialMaxCount,
  initialState,
  href,
}: LocationCardProps) => {
  const methodName = locationId && `lococc-${locationId}`;
  const data = useWebSocketData<LocationOccupationData>(methodName);
  const state = OCCUPATION_STATES[data?.state] ?? initialState;
  const count = data?.curOccupation ?? initialCount;
  const maxCount = data?.maxOccupation ?? initialMaxCount;

  return (
    <StyledLocationCard>
      <Link to={href}>
        <BlockWrapper>
          {image ? (
            <img src={image.url} className="image" />
          ) : (
            <div className="image" />
          )}
          <div className="locationName h-12">{locationName}</div>
          <div className="locationCity">{locationCity}</div>
          <CountBlock count={count} maxCount={maxCount} state={state} />
        </BlockWrapper>
      </Link>
    </StyledLocationCard>
  );
};

export default LocationCard;
