import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { addDays } from "date-fns";
import type { ExtendedQueryResult } from "./base.js";
import { formatApiDate } from "../../utils/date-time.js";
import RESTService from "../../services/RESTService.js";
import { DailyOccupancyData } from "../../api/v1/ReportData/index.js";

interface DailyOccupancyDataOptions {
    spaceId: string;
    enabled?: boolean;
    date: number;
};

async function queryFn({queryKey}) {
    const [_spaces, spaceId, _dailyocc, strDate] = queryKey;

    const selectedDate: number = +strDate;
    const startDate = new Date(selectedDate);
    const lastDate = addDays(startDate, 1);

    const params = new URLSearchParams();
    params.append("from", formatApiDate(startDate));
    params.append("till", formatApiDate(lastDate));

    return await RESTService.getAsync<DailyOccupancyData>({
        url: `Spaces/${spaceId}/dailyoccupancy?${params.toString()}`,
    });
}

export function useDailyOccupancyData({
    spaceId,
    enabled: enabledOption,
    date,
}: DailyOccupancyDataOptions): ExtendedQueryResult<DailyOccupancyData> {
    const today = new Date().setHours(0,0,0,0); // setHours returns new timestamp as side-effect
    const oneHour = 60 * 60 * 1000;
    const enabled = (enabledOption === undefined || enabledOption)
        && !!spaceId;
    const queryKey = useMemo(() =>
        ["Spaces", spaceId, "dailyoccupancy", date.toString()]
    , [spaceId, date]);
    const staleTime = date == today ? oneHour : Infinity;
    return {
        ...useQuery({
            enabled,
            queryKey,
            queryFn,
            staleTime,
        }),
        queryKey,
    };
}
