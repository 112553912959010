import { useMemo } from "react";
import { useQuery, QueryClient, UseQueryResult } from "@tanstack/react-query";
import RESTService from "~/services/RESTService.js";
import { baseQueryFn, staleTimeData, staleTimeInfo } from "./base.js";
import type { SpaceOptions, ExtendedQueryResult } from "./base.js";
import type { DataMap } from "./multi.js";
import { multiPlaceholder, queryFnSingle, queryFnMulti } from "./multi.js";
import { HeatmapInfo } from "../../api/v1/ReportInfo/index.js";
import { HeatmapData } from "../../api/v1/ReportData/index.js";

interface SpaceDataOptions<Result> extends SpaceOptions<Result> {
    queryClient: QueryClient;
}

interface SpaceZoneDataOptions<Result> extends SpaceDataOptions<Result> {
    heatmapZoneId: string;
}

interface HeatmapSummedDataOptions extends SpaceOptions<DataMap<number[][]>> {
    from: string;
    till: string;
}

export function useSpaceHeatmaps({
    spaceId,
    enabled: enabledOption,
    placeholderData,
}: SpaceOptions<HeatmapInfo[]>): UseQueryResult<HeatmapInfo[]> {
    const enabled = (enabledOption === undefined || enabledOption)
        && !!spaceId;
    return useQuery({
        enabled,
        queryKey: ["Spaces", spaceId, "HeatmapZones"],
        queryFn: baseQueryFn<HeatmapInfo[]>,
        placeholderData,
        staleTime: staleTimeInfo,
    });
}

export function useSpaceHeatmapLatestData({
    spaceId,
    heatmapZoneId,
    enabled: enabledOption,
    placeholderData,
    queryClient,
}: SpaceZoneDataOptions<HeatmapData>): ExtendedQueryResult<HeatmapData> {
    const enabled = (enabledOption === undefined || enabledOption)
        && !!spaceId && !!heatmapZoneId;
    const queryKey = useMemo(() =>
        ["Spaces", spaceId, "HeatmapZones", heatmapZoneId, "LatestData"]
    , [spaceId, heatmapZoneId]);
    return {
        ...useQuery({
            enabled,
            queryKey,
            queryFn: queryFnSingle<HeatmapData>,
            meta: {queryClient},
            placeholderData,
            staleTime: staleTimeData,
        }),
        queryKey,
    };
}

export function useSpaceHeatmapsLatestData({
    spaceId,
    enabled: enabledOption,
    placeholderData,
    queryClient,
}: SpaceDataOptions<DataMap<HeatmapData>>): ExtendedQueryResult<DataMap<HeatmapData>> {
    const enabled = (enabledOption === undefined || enabledOption)
        && !!spaceId;
    const queryKey = useMemo(() =>
        ["Spaces", spaceId, "HeatmapZones", multiPlaceholder, "LatestData"]
    , [spaceId]);
    return {
        ...useQuery({
            enabled,
            queryKey,
            queryFn: queryFnMulti<HeatmapData>,
            meta: {queryClient},
            placeholderData,
            staleTime: staleTimeData,
        }),
        queryKey,
    };
}

async function queryFnDateRange<Type>({queryKey}): Promise<Type> {
    const key = queryKey.slice(0,5);
    const from = queryKey[5];
    const till = queryKey[6];

    const urlPath = key.join('/');

    const params = new URLSearchParams();
    params.append("from", from);
    params.append("till", till);

    const url = `${urlPath}?${params.toString()}`;

    return await RESTService.getAsync<Type>({
        url,
    });
}

export function useSpaceHeatmapsSummedData({
    spaceId,
    enabled: enabledOption,
    placeholderData,
    from,
    till,
}: HeatmapSummedDataOptions): UseQueryResult<DataMap<number[][]>> {
    const enabled = (enabledOption === undefined || enabledOption)
        && !!spaceId;
    const queryKey = useMemo(() =>
        ["Spaces", spaceId, "HeatmapZones", multiPlaceholder, "SummedData", from, till]
    , [spaceId, from, till]);
    const staleTime = 24 * 60 * 60 * 1000;

    return useQuery({
        enabled,
        queryKey,
        queryFn: queryFnDateRange<DataMap<number[][]>>,
        staleTime,
    });
}
