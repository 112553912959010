import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Image as ImageApiType } from "~/api/v1/image.js";
import HeatmapConfig from "~/components/Dashboard/HeatMap/HeatMapConfig.js";
import { useSpace } from "~/hooks/api.js";

type HeatmapProps = {
  spaceId?: string;
};

export default function Heatmap({ spaceId: propSpaceId }: HeatmapProps) {
  const { id: routeSpaceId } = useParams<{ id: string }>();
  const spaceId = propSpaceId || routeSpaceId;
  const spaceOptions = { spaceId };

  const [image, setImage] = useState<ImageApiType>();
  const [imageWidth, setImageWidth] = useState(0);

  const { data: space } = useSpace(spaceOptions);
  const organizationId = space?.organization?.identifier;

  useEffect(() => {
    if (space) {
      const { heatmap_config: config } = space;

      if (!image) {
        setImage(config?.image);

        const img = new Image(); // Actual <img>
        img.src = config?.image?.url;

        img.onload = () => {
          setImageWidth(img.width);
        };
      }
    }
  }, [space]);

  if (space?.heatmap_config == null) return <></>;

  return (
    <>
      <HeatmapConfig
        organizationId={organizationId}
        spaceId={spaceId}
        radius={space?.heatmap_config?.radius_multiplier}
        scaling={space?.heatmap_config?.scaling_exponent}
        image={{ width: imageWidth }}
        heatmap={{
          positionX: space?.heatmap_config?.heatmap_pos_x,
          positionY: space?.heatmap_config?.heatmap_pos_y,
          width: space?.heatmap_config?.heatmap_width,
        }}
      >
        {image?.url ? (
          <img src={image.url} className="w-full h-auto" />
        ) : (
          <div className="image" />
        )}
      </HeatmapConfig>
    </>
  );
}
