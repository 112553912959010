import { useMemo } from "react";
import { useQuery, QueryClient, UseQueryResult } from "@tanstack/react-query";
import { addDays } from "date-fns";
import { formatApiDate } from "~/utils/date-time.js";
import RESTService from "~/services/RESTService.js";
import { baseQueryFn, staleTimeData, staleTimeInfo } from "./base.js";
import type { SpaceOptions, ExtendedQueryResult } from "./base.js";
import type { DataMap } from "./multi.js";
import { multiPlaceholder, queryFnSingle, queryFnMulti } from "./multi.js";
import { GateInfo } from "~/api/v1/ReportInfo/index.js";
import {
  DailyOccupancyData,
  GateData,
  PopularTimesData,
} from "~/api/v1/ReportData/index.js";

interface SpaceDataOptions<Result> extends SpaceOptions<Result> {
    queryClient: QueryClient;
}

interface SpaceGateDataOptions<Result> extends SpaceDataOptions<Result> {
    gateId: string;
}

export function useSpaceGates({
    spaceId,
    enabled: enabledOption,
    placeholderData,
}: SpaceOptions<GateInfo[]>): UseQueryResult<GateInfo[]> {
    const enabled = (enabledOption === undefined || enabledOption)
        && !!spaceId;
    const queryKey = ["Spaces", spaceId, "Gates"];
    return useQuery({
        enabled,
        queryKey,
        queryFn: baseQueryFn<GateInfo[]>,
        placeholderData,
        staleTime: staleTimeInfo,
    });
}

export function useSpaceGateLatestData({
    spaceId,
    gateId,
    enabled: enabledOption,
    placeholderData,
    queryClient,
}: SpaceGateDataOptions<GateData>): ExtendedQueryResult<GateData> {
    const enabled = (enabledOption === undefined || enabledOption)
        && !!spaceId && !!gateId;
    const queryKey = useMemo(() =>
        ["Spaces", spaceId, "Gates", gateId, "LatestData"]
    , [spaceId, gateId]);
    return {
        ...useQuery({
            enabled,
            queryKey,
            queryFn: queryFnSingle<GateData>,
            meta: {queryClient},
            placeholderData,
            staleTime: staleTimeData,
        }),
        queryKey,
    };
}

export function useSpaceGatesLatestData({
    spaceId,
    enabled: enabledOption,
    placeholderData,
    queryClient,
}: SpaceDataOptions<DataMap<GateData>>): ExtendedQueryResult<DataMap<GateData>> {
    const enabled = (enabledOption === undefined || enabledOption)
        && !!spaceId;
    const queryKey = useMemo(() =>
        ["Spaces", spaceId, "Gates", multiPlaceholder, "LatestData"]
    , [spaceId]);
    return {
        ...useQuery({
            enabled,
            queryKey,
            queryFn: queryFnMulti<GateData>,
            meta: {queryClient},
            placeholderData,
            staleTime: staleTimeData,
        }),
        queryKey,
    };
}

interface PopularOptions extends SpaceOptions<PopularTimesData> {
  //date: number;
}


export function useSpaceGatePopularTimesData({
  spaceId,
  enabled: enabledOption,
  //date, // TODO
}: PopularOptions): UseQueryResult<PopularTimesData> {
  const queryKey = ["Spaces", spaceId, "Gates", multiPlaceholder, "PopularTimes"];
  const enabled = (enabledOption === undefined || enabledOption)
    && !!spaceId;
  const staleTime = 24 * 60 * 60 * 1000;
  return useQuery({
    enabled,
    queryKey,
    queryFn: baseQueryFn<PopularTimesData>,
    staleTime,
  });
}

async function dayPresQueryFn({queryKey}) {
  const lastIndex = queryKey.length - 1;
  const strDate = queryKey[lastIndex];
  const urlPath = queryKey.slice(0, lastIndex).join('/');
  const dateNum: number = +strDate;
  const startDate = new Date(dateNum);
  const lastDate = addDays(startDate, 1);

  const params = new URLSearchParams();
  params.append("from", formatApiDate(startDate));
  params.append("till", formatApiDate(lastDate));

  const url = `${urlPath}?${params.toString()}`;

  return await RESTService.getAsync<DailyOccupancyData>({
    url,
  });
}

interface DayOptions extends SpaceOptions<PopularTimesData> {
  date: number;
}

export function useSpaceGateDayPresenceData({
  spaceId,
  enabled: enabledOption,
  date,
}: DayOptions): ExtendedQueryResult<DailyOccupancyData> {
  const today = new Date().setHours(0,0,0,0); // setHours returns new timestamp as side-effect
  const queryKey = ["Spaces", spaceId, "Gates", multiPlaceholder, "DayPresence", date.toString()];
  const enabled = (enabledOption === undefined || enabledOption)
    && !!spaceId;
  const oneHour = 60 * 60 * 1000;
  const staleTime = date == today ? oneHour : Infinity;
  return {
    ...useQuery({
      enabled,
      queryKey,
      queryFn: dayPresQueryFn,
      staleTime,
    }),
    queryKey,
  };
}
