import { useQueryClient } from "@tanstack/react-query";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  HourlyWeekData,
  PresenceData,
  UniqueCounterData,
} from "~/api/v1/ReportData/index.js";
import BlockWrapper from "~/components/atoms/BlockWrapper/BlockWrapper.js";
import AreaChart from "~/components/Dashboard/AreaChart/AreaChart.js";
import HeatMap from "~/components/Dashboard/HeatMap/HeatMap.js";
import HourlyPresence from "~/components/Dashboard/HourlyPresence/HourlyPresence.js";
import OccupancyBadge from "~/components/Dashboard/OccupancyBadge/OccupancyBadge.js";
import StatsCards from "~/components/Dashboard/StatsCards/StatsCards.js";
import VisitorAverages from "~/components/Dashboard/VisitorAverages/VisitorAverages.js";
import VisitorsPerDay from "~/components/Dashboard/VisitorsPerDay/VisitorsPerDay.js";
import VisitorsPerWeek from "~/components/Dashboard/VisitorsPerWeek/VisitorsPerWeek.js";
import ZoneList from "~/components/Dashboard/ZoneList/ZoneList.js";
import StyledDashboard from "~/components/pages/Dashboard/Dashboard.styles.js";
import ArrowHeader from "~/components/portal/ArrowHeader/ArrowHeader.js";
import DateSliderWeek from "~/components/UI/DateSliderWeek/DateSliderWeek.js";
import Grid from "~/components/UI/Grid/Grid.js";
import {
  DAYS_FULL,
  UserRole,
} from "~/constants.js";
import {
  useAllBinnedGateHackData,
  useBinnedPseudogateData,
  useTotalBinnedPseudogateData,
  useBinnedUniqueCountData,
  usePeakPresenceData,
  useSpace,
  useSpaces,
  useSpaceGates,
  useSpacePresenceZones,
  useSpacePresenceZonesLatestData,
  useSpacePseudogates,
  useSpacePseudogatesLatestData,
  useSpaceUniqueCounters,
  useSpaceUniqueCountersLatestData,
} from "~/hooks/api.js";
import useHourlyWeekDataGrid from "~/hooks/useHourlyWeekDataGrid.js";
import useUniqueWeekCountsHack from "~/hooks/useUniqueWeekCountsHack.js";
import {
  useWebSocketUpdatedMultiQuery,
  useWebSocketUpdatedQuery,
} from "~/hooks/websocketQuery.js";
import AuthService from "~/services/AuthService.js";
import { startOfWeek } from "~/utils/date-time.js";
import getNeighbors from "~/utils/getNeighbors.js";
import sumWeeklyGrid from "~/utils/sumWeeklyGrid.js";

type TotaledData = {
  total: number;
  person: number;
  runner: number;
  bike: number;
};

const LocationsAreaDashboardPage: React.FC = () => {
  const now = new Date().valueOf();

  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { id: spaceId } = useParams<{ id: string }>();
  const spacePHOOptions = { spaceId, queryClient, placeholderData: {} };
  const { data: spaces } = useSpaces({});

  const { data: locationData } = useSpace({ spaceId });
  const { data: gates } = useSpaceGates({ spaceId });
  const { data: pseudogates } = useSpacePseudogates({ spaceId });

  const organizationId = locationData?.organization?.identifier;

  const { data: presenceZones } = useSpacePresenceZones({ spaceId });
  const zoneIds = useMemo(() => {
    return Object.values(presenceZones ?? {}).map((zone) => zone.identifier);
  }, [presenceZones]);

  const { data: uniqueCounters } = useSpaceUniqueCounters({ spaceId });

  const { data: presenceZoneData, queryKey: presenceQuery } =
    useSpacePresenceZonesLatestData({
      spaceId,
      queryClient,
    });

  const { data: pseudogateData, queryKey: pseudogateQuery } =
    useSpacePseudogatesLatestData(spacePHOOptions);

  const presencePrefix = useMemo(() => `presence-${spaceId}`, [spaceId]);
  useWebSocketUpdatedMultiQuery<PresenceData>({
    queryKey: presenceQuery,
    groupPrefix: presencePrefix,
    items: zoneIds,
    queryClient,
    organizationId,
  });

  const { data: uniqueCounterData, queryKey: uniqueQuery } =
    useSpaceUniqueCountersLatestData({ spaceId });

  const uniqueMethod = useMemo(() => `uniquecounter-${spaceId}`, [spaceId]);
  useWebSocketUpdatedQuery<UniqueCounterData>({
    queryKey: uniqueQuery,
    methodName: uniqueMethod,
    queryClient,
    organizationId,
  });

  const [leftArrow, rightArrow] = getNeighbors(locationData, spaces);

  const canEdit = AuthService.hasRole(UserRole.Admin);

  const presenceZoneDataCombined = useMemo(() => {
    const values = Object.values(presenceZoneData ?? {});

    return values.reduce<TotaledData>(
      (acc, presenceData) => {
        if (!presenceData) return acc;

        const person = presenceData.person ?? 0;
        const runner = presenceData.runner ?? 0;
        const bike = presenceData.bike ?? 0;

        return {
          person: acc.person + person,
          runner: acc.runner + runner,
          bike: acc.bike + bike,
          total: acc.total + person + runner + bike,
        };
      },
      { person: 0, runner: 0, bike: 0, total: 0 },
    );
  }, [presenceZoneData]);

  const startDateOfCurrentWeek = startOfWeek(now).toISOString();

  const [startDateSelectedWeek, setStartDateSelectedWeek] = useState(
    startDateOfCurrentWeek,
  );

  const gateHackNames = ["gate_left", "gate_right"];
  const gateHackIds = useMemo(() => {
    return gates
      ?.filter((gate) => gateHackNames.includes(gate.name))
      ?.map((gate) => gate.identifier);
  }, [gates]);

  let gridFromPseudogate = false;
  let gridFromGateHack = false;
  let gridFromUniqueCounters = false;
  // TODO: Special case for 'f54a3e0b-c5ff-4364-bd2d-438751a2712f' (zuiderpark)?
  if (gates === undefined) {
  } else if (gates?.length > 0 && gateHackIds?.length == 2) {
    // Special case for smart@sea
    gridFromGateHack = true;
  } else if (pseudogates === undefined) {
  } else if (pseudogates?.length > 0) {
    gridFromPseudogate = true;
  } else if (uniqueCounters === undefined) {
  } else if (uniqueCounters?.length > 0) {
    gridFromUniqueCounters = true;
  } else {
  }

  const wholePseudoGateId =
    pseudogates?.length === 1
      ? pseudogates[0].identifier
      : (pseudogates?.find((obj) => obj.name === "whole-field"))
          ?.identifier;

  const { data: peakPresenceData } = usePeakPresenceData({
    spaceId,
    enabled: false, // Never this
    date: new Date(startDateSelectedWeek).getTime(),
  });
  const { data: binnedTotalPseudogateData } = useTotalBinnedPseudogateData({
    spaceId,
    enabled: gridFromPseudogate && wholePseudoGateId === undefined,
    date: new Date(startDateSelectedWeek).getTime(),
  });
  const { data: binnedWholefieldPseudogateData } = useBinnedPseudogateData({
    spaceId,
    gateId: wholePseudoGateId,
    enabled: gridFromPseudogate && wholePseudoGateId !== undefined,
    date: new Date(startDateSelectedWeek).getTime(),
  });
  const { data: binnedAllGateData } = useAllBinnedGateHackData({
    spaceId,
    enabled: gridFromGateHack,
    date: new Date(startDateSelectedWeek).getTime(),
  });
  const { data: binnedUniqueCountData } = useBinnedUniqueCountData({
    spaceId,
    enabled: gridFromUniqueCounters,
    date: new Date(startDateSelectedWeek).getTime(),
  });

  const dataUniqueCountPerCategory = useUniqueWeekCountsHack({
    spaceId,
    startOfWeekDate: startDateSelectedWeek,
  });

  const binnedGateData = useMemo(() => sumWeeklyGrid(
    binnedAllGateData,
    (gateId) => gateHackIds.includes(gateId)
  ), [gateHackIds, binnedAllGateData]);

  const weekDataSource =
    gridFromGateHack ? binnedGateData :
    gridFromPseudogate ?
      wholePseudoGateId === undefined ? binnedTotalPseudogateData : binnedWholefieldPseudogateData :
    gridFromUniqueCounters ? binnedUniqueCountData :
    undefined;

  const hourlyWeekDataGrid = useHourlyWeekDataGrid({
    data: weekDataSource,
  });

  if (!locationData) return <></>;

  return (
    <>
      <ArrowHeader
        name={[locationData.name]
          .filter(Boolean)
          .join(" | ")}
        leftUrl={leftArrow ? `/area/${leftArrow}/dashboard` : null}
        rightUrl={rightArrow ? `/area/${rightArrow}/dashboard` : null}
        editText={t("locations.dashboard.header.button")}
        editUrl={canEdit ? `/locations/${spaceId}/edit` : null}
      />

      <StyledDashboard>
        <Grid
          gap={16}
          numberOfColumns={{
            xl: 3,
          }}
        >
          <Grid.Cell
            className="infoCards"
            columnSpan={{
              xl: 2,
            }}
          >
            <DateSliderWeek
              onChange={(date) => {
                setStartDateSelectedWeek(startOfWeek(date).toISOString());
              }}
            />

            {wholePseudoGateId && (
              <VisitorAverages pseudoGateId={wholePseudoGateId} />
            )}

            {wholePseudoGateId && (
              <VisitorsPerWeek pseudoGateId={wholePseudoGateId} />
            )}

            {wholePseudoGateId && (
              <VisitorsPerDay
                pseudoGateId={wholePseudoGateId}
                startDate={startDateSelectedWeek}
              />
            )}

            {uniqueCounters?.length > 0 ? (
              <AreaChart
                type="stacked"
                title="Category Count"
                // @ts-expect-error TODO: fix types
                data={dataUniqueCountPerCategory.data}
                categories={dataUniqueCountPerCategory.categories}
              />
            ) : null}

            <Grid gap={16}>
              {hourlyWeekDataGrid && <HourlyPresence
                headerName="HourlyVisitors"
                data={hourlyWeekDataGrid}
              />}
            </Grid>

            <HeatMap />
          </Grid.Cell>
          <Grid.Cell>
            <Grid gap={16}>
              <BlockWrapper>
                <h2
                  style={{
                    fontSize: "24px",
                    fontWeight: 500,
                    marginBottom: 0,
                  }}
                >
                  Live Events
                </h2>
              </BlockWrapper>

              <StatsCards
                presenceZoneDataCombined={presenceZoneDataCombined}
                uniqueCounterData={uniqueCounterData}
              />

              <ZoneList organizationId={organizationId} spaceId={spaceId} />
            </Grid>
          </Grid.Cell>
        </Grid>
      </StyledDashboard>
    </>
  );
};

export default LocationsAreaDashboardPage;
