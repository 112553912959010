// cSpell:language nl

export default {
  translation: {
    navigation: {
      header: "Menu",
      search: "Zoeken",
    },

    validationRules: {
      mixed: {
        default: "${path} is ongeldig",
        required: "Vul alstublieft dit veld in.",
        oneOf: "${path} moet een van de volgende waardes zijn: ${values}",
        notOneOf: "${path} mag geen van de volgende waardes zijn: ${values}",
      },

      string: {
        length: "Dit veld moet precies ${length} tekens bevatten",
        min: "Dit veld moet tenminste ${min} tekens bevatten",
        max: "Dit veld mag maximaal ${max} tekens bevatten",
        matches: '${path} moet overeen komen met: "${regex}"',
        email: "Vul een geldig e-mailadres in.",
        url: "${path} moet een geldige URL zijn",
        lowercase: "${path} mag alleen kleine letters bevatten",
        uppercase: "${path} mag alleen hoofdletters bevatten",
        valid_password: "Voer een geldig wachtwoord in",
        password_match: "De wachtwoorden moeten hetzelfde zijn",
        invalid_phone: "Ongeldig telefoonnummer",
      },

      number: {
        min: "${path} moet tenminste ${min} zijn",
        max: "${path} mag maximaal ${max} zijn",
        lessThan: "${path} moet minder zijn dan ${less}",
        moreThan: "Veld waarde moet groter zijn dan ${more}",
        notEqual: "${path} moet niet gelijk zijn aan ${notEqual}",
        positive: "${path} moet een positief getal zijn",
        negative: "${path} moet een negatief getal zijn",
        integer: "Voer alstublieft een geldig nummer in.",
      },

      date: {
        min: "${path} moet later zijn dan ${min}",
        max: "${path} moet eerder zijn dan ${max}",
      },

      object: {
        noUnknown:
          "${path} field can not have keys not specified in the object shape",
      },

      array: {
        min: "${path} moet tenminste ${min} waardes bevatten",
        max: "${path} mag maximaal ${max} waardes bevatten",
      },
    },

    attributes: {
      name: "Naam",
      initials: "Voorletters",
      firstName: "Voornaam",
      lastName: "Achternaam",
      password: "Wachtwoord",
      passwordConfirmation: "Wachtwoord (herhaal)",
      createdAt: "Aangemaakt op",
      updatedAt: "Laatst bijgewerkt op",
      gender: "Geslacht",

      address: "Adres",
      postalCode: "Postcode",
      houseNumber: "Huisnummer",
      houseNumberSuffix: "Toevoeging",
      street: "Straat",
      city: "Plaatsnaam",
      country: "Land",
      phoneNumber: "Telefoonnummer",
      email: "E-mail",
      profileImage: "Profiel afbeelding",

      companyName: "Bedrijfsnaam",
      companyLogo: "Bedrijfslogo",
      legalForm: "Rechtsvorm",
      vatNumber: "BTW nummer",
      companyRegistrationNumber: "KvK nummer",

      dateOfBirth: "Geboortedatum",
    },

    dateValues: {
      days: {
        short: {
          mon: "ma",
          tue: "di",
          wed: "wo",
          thu: "do",
          fri: "vr",
          sat: "za",
          sun: "zo",
        },
      },
    },

    state: {
      ok: "Normaal",
      warning: "Druk",
      danger: "Vol",
    },

    button: {
      submit: "Verstuur",
    },

    confirmModal: {
      defaultTitle: "Let op",
      defaultMessage: "Weet je zeker dat je dit wil doen?",
      defaultDeleteMessage: "Weet je zeker dat je dit wil verwijderen?",
      buttonYes: "Ja",
      buttonNo: "Nee",
    },

    croppedFileUpload: {
      uploadFile: "Afbeelding selecteren",
      saveFile: "Afbeelding opslaan",
      deleteButton: "Verwijder afbeelding",
      errors: {
        fileType: "Alleen een .png of .jpeg bestandsformaat is toegestaan",
        fileSize: "De maximum bestandsgrootte is {{size}}MB",
      },
    },

    connection: {
      lostConnection: "Connectie verloren (opnieuw verbinden...)",
    },
    apiErrors: {
      notFound: "De pagina die u probeert te bezoeken bestaat niet (meer)",
      notFoundPage:
        "We weten dat je ergens heen wilde, maar kon het hier niet vinden",
      unexpected:
        'Er heeft zich een onbekende fout voorgedaan, neem contact op met support <a href="mailto:support@husense.nl">support@husense.nl</a>',
      contact: "Neem contact op support@husense.nl",
    },

    /////////////////// specific ///////////////////
    auth: {
      login: {
        header: "Inloggen",
        instructions: "Vul hieronder jouw gegevens in",
        email: "Email adres",
        password: "Wachtwoord",
        passwordConfirmed: "Wachtwoord opnieuw",
        submit: "Inloggen",
        forgotPassword: "Wachtwoord vergeten?",
        register: "Hier kan jij je registreren",
        emailVerification: "Jouw email adres is bevestigd, je kan nu inloggen",
        backToLogin: "Terug naar inloggen",
        goToTheLoginPage: "Of ga naar de inlogpagina",
      },
      register: {
        title: "Registreer",
        thankYou: {
          header: "Bedankt voor het registreren bij Husense",
          body: "Een email met login-link wordt naar jouw email verzonden.",
        },
        submit: "Registreer",
      },
      responses: {
        incorrectCredentials: "Email adres en wachtwoord komen niet overeen",
        unknown: "Er heeft zich een onbekende fout voorgedaan",
      },
      logout: "Uitloggen",
      forgotPassword: {
        title: "Wachtwoord vergeten?",
        body: "Wij  sturen een link naar het door jou ingevulde e-mailadres. Gebruik deze link om vervolgens een nieuw wachtwoord in te stellen. ",
        submit: "Versturen",
        thankYou: {
          header: "Wachtwoord resetten",
          body: "Open de e-mail die wij verzonden hebben om het wachtwoord te resetten.",
        },
        unknownEmail: "Het e-mail adres {{email}} is onbekend in ons systeem.",
      },
      resetPassword: {
        title: "Nieuw wachtwoord",
        resetPasswordBody: "Voer het gewenste wachtwoord twee keer in",
        firstTimeBody:
          "Bedenk een veilig wachtwoord voor je gebruikersaccount.",
        password: "Wachtwoord",
        passwordConfirmed: "Wachtwoord opnieuw",
        submit: "Wachtwoord wijzigen",
        invalidPassword:
          "Wachtwoord komt niet overeen of is niet lang genoeg (minimaal 8 karakters).",
      },
      passwordCheck: {
        minLength: "Minimaal 8 karakters",
        smallLetter: "Kleine letters",
        largeLetter: "Hoofdletters",
        number: "Minimaal een nummer",
        nonaplha: "Niet alfanumeriek #@:$%^&",
      },
      emailVerification: {
        error: {
          header: "E-mail verificatiefout",
          body: "Deze link is niet meer geldig, vul Reset wachtwoord in om een nieuwe link te krijgen",
        },
      },
    },

    locations: {
      types: {
        none: "Geen",
        building: "Gebouw",
        floor: "Verdieping",
        room: "Ruimte",
      },
      index: {
        header: {
          title: "Locaties",
          button: "Locatie toevoegen",
        },
      },
      form: {
        header: {
          create: "Locatie aanmaken",
          edit: "Locatie bewerken",
          times: "Openingstijden",
        },
        name: "Locatie naam",
        type: "Type locatie",
        capacity: "Capaciteit",
        areaSize: "Oppervlakte (m2)",
        openingTime: "Open",
        closingTime: "Gesloten",
        countResetTime: "Locatie telling reset tijd",
        description: "Beschrijving",
      },
      dashboard: {
        header: {
          title: "Locatie dashboard",
          liveEvents: "Live data",
          button: "Bewerk locatie",
          buttonDelete: "Verwijder locatie",
        },
      },
      area: {
        index: {
          header: {
            title: "Gebied",
            button: "Gebied toevoegen",
          },
        },
      },
      smartcity: {
        dashboard: {
          header: {
            title: "Husense SmartCity dashboard",
          },
        },
      },
    },

    devices: {
      types: {
        none: "Geen",
        peopletrackingsensor: "People counting sensor",
      },
      index: {
        header: {
          title: "IOT Apparaten",
          button: "Apparaat toevoegen",
        },
      },
      form: {
        name: "Apparaat naam",
        type: "Apparaat type",
        space: "Locatie",
        connString: "Connectie string",
        buttonBack: "Ga terug naar apparaten",
      },
    },

    devicesList: {
      header: {
        title: "IOT Apparaten",
      },
    },

    bigScreen: {
      header: {
        ok: "Welkom",
        warning: "Houd a.u.b. afstand en let op elkaar",
        danger: "Het is druk!",
      },
      subHeader: {
        ok: "Houd a.u.b. afstand en let op elkaar",
        warning: "",
        danger: "Wacht a.u.b. even.",
        lostConnection: "Scherm buiten gebruik",
      },
      peopleSubText: "Mensen binnen",
      poweredBy: "Mogelijk gemaakt door",
    },

    dashboard: {
      popularTimes: {
        header: "Populaire tijden",
      },
      capacity: {
        header: "Capaciteit",
        lostConnection: "Verbinding aan het maken...",
      },
      HourlyPresence: {
        header: "Maximale aanwezigheid per uur",
      },
      HourlyGatePresence: {
        header: "Aanwezigheid per uur",
      },
      HourlyVisitors: {
        header: "Bezoekers per uur",
      },
      liveEvents: {
        header: "Live telling",
        numberPeople: "# mensen",
        filled: "max %",
      },
      liveEventsCum: {
        header: "Dag totaal",
        peak: "Piek",
        average: "Gem.",
        utilization: "Util. %",
      },
      floorOverview: {
        header: "Verdiepingen overzicht",
      },
    },

    account: {
      organization: {
        header: "Organisatie gegevens",
      },
      user: {
        header: "Persoonlijk account gegevens",
        passwordChange: {
          header: "Wachtwoord aanpassen",
          body: "Het wijzigen van het wachtwoord kan vanuit veiligheidsoverwegingen alleen via de wachtwoord herstel pagina.",
          button: "Klik hier om het wachtwoord aan te passen",
        },
      },
      administration: {
        header: "Administratieve contactpersoon",
      },
      updateMessage: "Gegevens succesvol opgeslagen!",
    },

    zonesList: {
      header: {
        title: "Zones",
      },
      global: {
        name: "Totaal",
      },
    },
  },
};
