import { Link } from "react-router-dom";
import Header from "..//HeaderNew/Header.js";
import Button from "../../UI/Button/Button.js";
import ArrowButton from "../../UI/ArrowButton/ArrowButton.js";
import { SimpleSpace } from "~/api/v1/Space";

type ArrowHeaderProps = {
  name: string;
  parent?: SimpleSpace;
  leftUrl: string | null;
  rightUrl: string | null;
  editText: string;
  editUrl: string | null;
};

const ArrowHeader: React.FC<ArrowHeaderProps> = ({
  name,
  parent,
  leftUrl,
  rightUrl,
  editText,
  editUrl,
}: ArrowHeaderProps) => {
  return (
    <Header>
      <div
        className="flex flex-row items-center"
        style={{ columnGap: "24px" }}
      >
        <ArrowButton
          disabled={leftUrl === null}
          href={leftUrl}
          mirror={true}
        />

        <h2
          className="flex flex-row items-center"
          style={{ columnGap: "12px" }}
        >
          {parent && (<>
            <Link to={`./../../${parent.identifier}/dashboard`}>
              {parent.name}
            </Link>
            <span >
              -
            </span>
          </>)}
          {name}
        </h2>
      </div>

      <div
        className="flex flex-row items-center"
        style={{ columnGap: "16px" }}
      >
        {editUrl && (
          <Button
            label={editText}
            url={editUrl}
          />
        )}

        <ArrowButton
          disabled={rightUrl === null}
          href={rightUrl}
        />
      </div>
    </Header>
  );
};

export default ArrowHeader;
