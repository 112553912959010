import React from "react";

type RouteType = {
  path: string;
  element: any;
  public: boolean;
  admin_only?: boolean;
  superadmin_only?: boolean;
};

import * as Auth from "./pages/Auth/index.js";
import SocialDistance from "./pages/BigScreenScreen/BigScreenPage.js";
import * as Devices from "./pages/Devices/index.js";
import PageNotFoundPage from "./pages/Error/PageNotFound.js";
// TODO: split 'Locations' into Areas and Buildings(?)
import * as Areas from "./pages/Areas/index.js";
import * as Locations from "./pages/Locations/index.js";

export const routes: RouteType[] = [
  ///// Auth /////
  // Register //TODO: enable registration again on a later point if needed
  // { path: '/register', component: Auth.RegisterPage, public: true },
  // { path: '/register/thankyou', component: Auth.RegisterThankYouPage, public: true },
  //Verify email
  {
    path: "/verify/:user_id/:verification_token",
    element: (
      <React.Suspense fallback={<>...</>}>
        <Auth.EmailVerificationPage />
      </React.Suspense>
    ),
    public: true,
  },
  {
    path: "/verify/error",
    element: (
      <React.Suspense fallback={<>...</>}>
        <Auth.EmailVerificationErrorPage />
      </React.Suspense>
    ),
    public: true,
  },
  //Login
  {
    path: "/login",
    element: (
      <React.Suspense fallback={<>...</>}>
        <Auth.LoginPage />
      </React.Suspense>
    ),
    public: true,
  },
  //Password forgotten
  {
    path: "/password-forgotten",
    element: (
      <React.Suspense fallback={<>...</>}>
        <Auth.PasswordForgottenPage />
      </React.Suspense>
    ),
    public: true,
  },
  {
    path: "/password-forgotten/thankyou",
    element: (
      <React.Suspense fallback={<>...</>}>
        <Auth.PasswordForgottenThankYouPage />
      </React.Suspense>
    ),
    public: true,
  },
  {
    path: "/reset-password/:user_id/:reset_token",
    element: (
      <React.Suspense fallback={<>...</>}>
        <Auth.PasswordResetPage />
      </React.Suspense>
    ),
    public: true,
  },
  //Account
  {
    path: "/account",
    element: (
      <React.Suspense fallback={<>...</>}>
        <Auth.AccountPage />
      </React.Suspense>
    ),
    public: false,
    admin_only: true,
  },
  {
    path: "/user-account",
    element: (
      <React.Suspense fallback={<>...</>}>
        <Auth.UserAccountPage />
      </React.Suspense>
    ),
    public: false,
  },

  ///// Social distancing screen /////
  { path: "/screen/:token", element: <SocialDistance />, public: true },

  ///// TV screen screen /////
  {
    path: "/screen/:token/dashboard",
    element: (
      <React.Suspense fallback={<>...</>}>
        <Locations.TvDashboard />
      </React.Suspense>
    ),
    public: true,
  },

  ///// Locations /////
  // TODO: check what we should do with the locations index page (LocationsFloorIndexPage now enabled for demo purposes)
  {
    path: "/",
    element: (
      <React.Suspense fallback={<>...</>}>
        <Locations.LocationsIndexDemoPage />
      </React.Suspense>
    ),
    public: false,
  },
  {
    path: "/locations",
    element: (
      <React.Suspense fallback={<>...</>}>
        <Locations.LocationsIndexPage />
      </React.Suspense>
    ),
    public: false,
  },
  {
    path: "/locations/fake",
    element: (
      <React.Suspense fallback={<>...</>}>
        <Locations.LocationsIndexDemoPage />
      </React.Suspense>
    ),
    public: false,
  },
  {
    path: "/locations/apollo14",
    element: (
      <React.Suspense fallback={<>...</>}>
        <Locations.LocationsFloorIndexPage />
      </React.Suspense>
    ),
    public: false,
  },
  {
    path: "/locations/create",
    element: (
      <React.Suspense fallback={<>...</>}>
        <Locations.LocationsFormPage />
      </React.Suspense>
    ),
    public: false,
    admin_only: true,
  },
  {
    path: "/locations/:id/edit",
    element: (
      <React.Suspense fallback={<>...</>}>
        <Locations.LocationsFormPage />
      </React.Suspense>
    ),
    public: false,
    admin_only: true,
  },
  {
    path: "/locations/:id/dashboard",
    element: (
      <React.Suspense fallback={<>...</>}>
        <Locations.LocationsDashboardPage />
      </React.Suspense>
    ),
    public: false,
  },
  {
    path: "/area/",
    element: (
      <React.Suspense fallback={<>...</>}>
        <Locations.LocationsAreaIndexPage />
      </React.Suspense>
    ),
    public: false,
  },
  {
    path: "/area/:id/dashboard",
    element: (
      <React.Suspense fallback={<>...</>}>
        <Locations.LocationsAreaDashboardPage />
      </React.Suspense>
    ),
    public: false,
  },
  {
    path: "/area/:id/dashboard/debug",
    element: (
      <React.Suspense fallback={<>...</>}>
        <Areas.AreaDebug />
      </React.Suspense>
    ),
    public: false,
    superadmin_only: true,
  },
  {
    path: "/locations/:id/dashboard/debug",
    element: (
      <React.Suspense fallback={<>...</>}>
        <Areas.AreaDebug />
      </React.Suspense>
    ),
    public: false,
    superadmin_only: true,
  },
  {
    path: "/area/:id/dashboard/heatmaptest",
    element: (
      <React.Suspense fallback={<>...</>}>
        <Areas.AreaHeatmapTest />
      </React.Suspense>
    ),
    public: false,
    superadmin_only: true,
  },
  {
    path: "/locations/:id/dashboard/heatmaptest",
    element: (
      <React.Suspense fallback={<>...</>}>
        <Areas.AreaHeatmapTest />
      </React.Suspense>
    ),
    public: false,
    superadmin_only: true,
  },

  ///// Devices /////
  {
    path: "/devices",
    element: (
      <React.Suspense fallback={<>...</>}>
        <Devices.DevicesIndexPage />
      </React.Suspense>
    ),
    public: false,
  },
  {
    path: "/devices/create",
    element: (
      <React.Suspense fallback={<>...</>}>
        <Devices.DevicesFormPage />
      </React.Suspense>
    ),
    public: false,
    admin_only: true,
  },
  {
    path: "/devices/:id/edit",
    element: (
      <React.Suspense fallback={<>...</>}>
        <Devices.DevicesFormPage />
      </React.Suspense>
    ),
    public: false,
    admin_only: true,
  },
  {
    path: "*",
    element: <PageNotFoundPage />,
    public: true,
  },
];

export const authenticatedRoutes = routes.filter((route) => !route.public);
export const unauthenticatedRoutes = routes.filter((route) => route.public);
export const allRoutes = routes;
